import Social from "../component/Social";
import Filter from "../component/fillter-new";
import logo from "../image/logo.png";
import phone from "../image/phone-white.png";
import React, { useState, useEffect } from "react";
import Menu from "./Menu";

function Header() {
  const [season, setSeason] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [diameter, setDiameter] = useState("");
  const [brand, setBrand] = useState("");

  useEffect(() => {
    const savedSeason = localStorage.getItem("season");
    const savedWidth = localStorage.getItem("width");
    const savedHeight = localStorage.getItem("height");
    const savedDiameter = localStorage.getItem("diameter");
    const savedBrand = localStorage.getItem("brand");

    if (savedSeason) setSeason(savedSeason);
    if (savedWidth) setWidth(savedWidth);
    if (savedHeight) setHeight(savedHeight);
    if (savedDiameter) setDiameter(savedDiameter);
    if (savedBrand) setBrand(savedBrand);
  }, []);

  useEffect(() => {
    localStorage.setItem("season", season);
    localStorage.setItem("width", width);
    localStorage.setItem("height", height);
    localStorage.setItem("diameter", diameter);
    localStorage.setItem("brand", brand);
  }, [season, width, height, diameter, brand]);
  const updateFilter = (filterType, value) => {
    switch (filterType) {
      case "season":
        setSeason(value);
        break;
      case "width":
        setWidth(value);
        break;
      case "height":
        setHeight(value);
        break;
      case "diameter":
        setDiameter(value);
        break;
      case "brand":
        setBrand(value);
        break;
      default:
        break;
    }
  };

  const [activeFilter, setActiveFilter] = useState("param");

  useEffect(() => {
    // Находим все элементы с data-fillter="param" или data-fillter="mark"
    const filterItemsParam = document.querySelectorAll(
      '.item[data-fillter="param"]'
    );
    const filterItemsMark = document.querySelectorAll(
      '.item[data-fillter="mark"]'
    );

    // Удаляем класс 'active' у всех элементов
    filterItemsParam.forEach((item) => item.classList.remove("active"));
    filterItemsMark.forEach((item) => item.classList.remove("active"));

    // Добавляем класс 'active' к элементам в зависимости от выбранного фильтра
    if (activeFilter === "param") {
      filterItemsParam.forEach((item) => item.classList.add("active"));
    } else if (activeFilter === "mark") {
      filterItemsMark.forEach((item) => item.classList.add("active"));
    }
  }, [activeFilter]); // Эффект будет повторно запускаться при изменении activeFilter

  const handleFilterClick = (event, filterType) => {
    event.preventDefault();
    setActiveFilter(filterType);
    const filter_result_block = document.querySelector(".fillter-result");
    filter_result_block.classList.remove("active");
  };

  return (
    <>
      <header>
        <div className="container">
          <div className="top_line">
            <div className="logo">
              <a href="/" className="logo">
                <img src={logo} alt="" />
              </a>
            </div>
            <Menu />
            <Social />
            <div className="phone_area">
              <div className="phone-item">
                {" "}
                <img src={phone} alt="" />
                <a href="tel:095 706 45 00">095 706 45 00</a>
              </div>
              <div className="phone-item">
                {" "}
                <img src={phone} alt="" />
                <a href="tel:098 858 23 80">098 858 23 80</a>
              </div>
              <div className="phone-item">
                {" "}
                <img src={phone} alt="" />
                <a href="tel:093 707 88 01">093 707 88 01</a>
              </div>
            </div>
          </div>

          <div className="hero">
            <h1>
              Обери ідеальні шини <br />
              для свого автомобіля з Rezina13
            </h1>

            <div className="navigation_filter">
              <a
                className={activeFilter === "param" ? "active" : ""}
                onClick={(event) => handleFilterClick(event, "param")}
                data-fill="param">
                За параметрами
              </a>
              <a
                className={activeFilter === "mark" ? "active" : ""}
                onClick={(event) => handleFilterClick(event, "mark")}
                data-fill="mark">
                По марці авто
              </a>
            </div>

            <Filter updateFilter={updateFilter} activeFilter={activeFilter} />
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
