const filtersInitialState = {
  filters: {
    season: "",
    width: "",
    height: "",
    diameter: "",
    brand: "",
  },
};

const productInitialState = {
  productDetails: {},
  orderProduct: null, // добавьте это
  isModalActive: false, // и это
};

const filtersReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "SET_FILTERS":
    case "UPDATE_FILTERS": // Объединил обработку двух экшенов, так как они делают одно и то же
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    default:
      return state;
  }
};

const productReducer = (state = productInitialState, action) => {
  switch (action.type) {
    case "SET_PRODUCT_DETAILS":
      return {
        ...state,
        productDetails: action.payload,
      };
    case "SET_ORDER_PRODUCT":
      return {
        ...state,
        orderProduct: action.payload,
      };
    case "SET_MODAL_ACTIVE":
      return {
        ...state,
        isModalActive: action.payload,
      };
    default:
      return state;
  }
};

// Используйте именованный экспорт для экспорта нескольких значений
export { filtersReducer, productReducer };
