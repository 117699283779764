import facebok from "../image/facebook.png";
import inst from "../image/instagram.png"
import tg from "../image/telegram.png"
import viber from "../image/viber.png"
function Social() {
    return (
        <>
        <div className="social_block">
            <a href="https://www.facebook.com/Rezina13" className="social_block--fb"><img src={facebok} alt="" /></a>
            <a href="https://www.instagram.com/rezina__13/" className="social_block--insta"><img src={inst} alt="" /></a>
            <a href="https://t.me//rezina13/" className="social_block--insta"><img src={tg} alt="" /></a>
            <a href="viber://chat?number=0937078801" className="social_block--insta"><img src={viber} alt="" /></a>
        </div>
        </>
    ); 
};
export default Social;