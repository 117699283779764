import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import com from "../image/com.png";
import {
  setProductDetails,
  setOrderProduct,
  setModalActive,
} from "../redux/actions";
import nova from '../image/nova.png';
function OrderModal(props) {
  const orderProduct = useSelector(
    (state) => state.productDetails.orderProduct
  );
  const isModalActive = useSelector(
    (state) => state.productDetails.isModalActive
  );
  console.log(orderProduct);

  const [quantity, setQuantity] = useState(1); // начальное значение количества

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const dispatch = useDispatch();
  const hendlerClose = () => {
    dispatch(setModalActive(false));
    dispatch(setOrderProduct(null));
  };


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    
    const response = await fetch('https://lp.rezina13.com.ua/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        name: event.target.elements.name.value,
        phone: event.target.elements.phone.value,
        post: event.target.elements.post.value,
        product: event.target.elements.product.value,
        price: event.target.elements.price.value,
        qunt: event.target.elements.qunt.value,

      }).toString(),
    });
  
    if (response.ok) {
      console.log("Email успешно отправлен");
      setIsSubmitting(false);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        hendlerClose();
      }, 3000);
    } else {
      console.error("Ошибка при отправке email");
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <div className={`modal_order ${isModalActive ? "active" : ""}`}>
      
        
        <div className="modal_order_wrap">
        
        {isSubmitting && <div className="spinner"> <div className="loader"></div>Отправка...</div>}
        {isSubmitted && <div className="success-message"><img src={com} alt="" />Заказ успешно отправлен</div>}
          <button className="close" onClick={hendlerClose}>
            x
          </button>
          <div className="left">
            <div className="product_modal">
              <div className="top_level">
                <img src={orderProduct?.thumb} alt="" />
                <div className="quantity">
                  <span className="title">{orderProduct?.name}</span>
                  <div className="quantity-controls">
                    <button onClick={decrementQuantity}>-</button>
                    <span>{quantity}</span>
                    <button onClick={incrementQuantity}>+</button>
                  </div>
                </div>
                <div className="price">{orderProduct?.price}</div>
              </div>
              <div className="bottom_level">
                {orderProduct?.attributes?.[0]?.attribute?.map(
                  (attr, index) => {
                    if (
                      attr.name !== "Фото" &&
                      attr.name !== "НаименованиеИнтернет" &&
                      attr.name !== "Наименование интернет" &&
                      attr.name !== "удалить_ШИП"
                    ) {
                      return (
                        <div className="item" key={index}>
                          <span>{attr.name}</span>
                          <span>{attr.text}</span>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </div>
          </div>
          <div className="right">
            <h2>Оформлення замовлення</h2>
            <p>
              Залиште свої контактні данні. Наш менеджер зв'яжеться з вами для
              уточнення доставки вашого замовлення
            </p>
            <form onSubmit={handleSubmit}> 
            <input type="text" name="name" placeholder="Введіть Ім'я" />
            <input type="text" name="phone" placeholder="Телефон" />
            <input
              type="text"
              name="post"
              placeholder="Місто та відділення пошти"
            />
            <input type="hidden" name="product" value={orderProduct?.name} />
            <input type="hidden" name="price" value={orderProduct?.price} />
            <input type="hidden" name="qunt" value={quantity} />
            <small>
              Ваші персональні данні не будут викростовуватися 3-ми особами
            </small>
            <button>Відправити замовлення</button>
            </form>
            <div className="shipping">
                  <img src={nova} alt="" />
                  <div className="content">
                    <span className="title">Доставка по всій Україні</span>
                    <p>Ваше замовлення приїде до Вас у любу точку країни. Якщо виникли пытання, ми завжди раді вам Допомогти <a href="tel:095 706 45 00">095 706 45 00</a></p>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderModal;
