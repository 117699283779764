import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from "../redux/actions";
function Menu() {
  const [activeLink, setActiveLink] = useState(null);
  const dispatch = useDispatch();
  const filtersInitialState = useSelector(state => state.filters);
  console.log(filtersInitialState)
  // const handeClearFillter = () => {
  //   const filters = {
  //     season: "",
  //     width: "",
  //     height: "",
  //     diameter: "",
  //     brand: "",
  //   };

  //   // Отправляем действие с данными фильтра в хранилище
  //   dispatch(setFilter(filters));

  //   // Если нужно, вызываем updateFilter для обновления состояния вне Redux
  //   updateFilter("season", filters.season);
  //   updateFilter("width", filters.width);
  //   updateFilter("height", filters.height);
  //   updateFilter("diameter", filters.diameter);
  //   updateFilter("brand", filters.brand);
  // };

  const clearFilters = () => {
    const emptyFilters = {
      season: "",
      width: "",
      height: "",
      diameter: "",
      brand: "",
    };
  
    dispatch(setFilter(emptyFilters));

    localStorage.removeItem('season');
    localStorage.removeItem('width');
    localStorage.removeItem('height');
    localStorage.removeItem('diameter');
    localStorage.removeItem('brand');
  };
  const handleClick = (name) => {
    setActiveLink(name);
    clearFilters();
  };



  return (
    <>
      <div className="nav">
        <li>
          <Link
            to="/"
            className={activeLink === 'shini' ? 'active' : ''}
            onClick={() => handleClick('shini')}
          >
            Шини
          </Link>
        </li>
        <li>
          <Link
            to="/diski"
            className={activeLink === 'diski' ? 'active' : ''}
            onClick={() => handleClick('diski')}
          >
            Диски
          </Link>
        </li>
        <li>
          <Link
            to="/accessories "
            className={activeLink === 'accessories ' ? 'active' : ''}
            onClick={() => handleClick('accessories ')}
          >
            Аксесуари
          </Link>
        </li>
      </div>
    </>
  );
}

export default Menu;