import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../dom/Footer";
import logo from "../image/logo.png";
import phone from "../image/phone-white.png";
import SingleRelatedItem from "./SingleRelatedItem";
import s1 from "../image/s1.png";
import s2 from "../image/s2.png";
import s3 from "../image/s3.png";
import s4 from "../image/s4.png";
import s5 from "../image/s5.png";
import s6 from "../image/s6.png";
import s7 from "../image/s7.png";
import s8 from "../image/s8.png";
import call from "../image/call.png";
import visa from "../image/visa.png";
import nova from "../image/nova.png";
import cart from "../image/cart.png";
import fill_w from "../image/fill_2.png";
import Social from "../component/Social";
import Menu from "../dom/Menu";
import {setOrderProduct, setModalActive} from '../redux/actions';

function SingleProduct() {
  const productDetails = useSelector(
    (state) => state.productDetails.productDetails
  );

  const visibleProducts = 5;

  const [quantity, setQuantity] = useState(1); // начальное значение количества

  const decrementQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const incrementQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const dispatch = useDispatch();
  const handleOrderClick = () => {
    dispatch(setOrderProduct(productDetails));
    dispatch(setModalActive(true));

  };

  const [isTyre, setIsTyre] = useState(false);
  const [isDiski, setIsDiski] = useState(false);
  const [isAcss, setIsAcss] = useState(false);
  const [productAttributes, setProductAttributes] = useState(null);
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/product-attr?id=" + window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1))
      .then((response) => response.json())
      .then((fetchedData) => {
        setProductAttributes(fetchedData);
        fetchedData.filter((attr) => attr.name == 'Ширина').length > 0 ? setIsTyre(true) : setIsTyre(false);
        fetchedData.filter((attr) => attr.name == 'dia').length > 0 ? setIsDiski(true) : setIsDiski(false);
        !isDiski && !isTyre ? setIsAcss(true) : setIsAcss(false);
      });
  }, [isTyre, isDiski, isAcss]);

  const [tiresData, setTiresData] = useState(null);
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/tires")
      .then((response) => response.json())
      .then((fetchedData) => setTiresData(fetchedData));
  }, []);

  const [diskiData, setDiskiData] = useState(null);
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/db-diski")
      .then((response) => response.json())
      .then((fetchedData) => setDiskiData(fetchedData));
  }, []);

  const [accData, setAccData] = useState(null);
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/acc")
      .then((response) => response.json())
      .then((fetchedData) => setAccData(fetchedData));
  }, []);

  return (
    <>
         <header className="accessories">
            <div className="container">
                <div className="top_line accessories">
                    <div className="logo">
                        <a href="/" className="logo"><img src={logo} alt="" /></a>
                    </div>
                    <Menu />
                    <Social />
                    <div className="phone_area">
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:095 706 45 00">095 706 45 00</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:098 858 23 80">098 858 23 80</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:093 707 88 01">093 707 88 01</a></div>

                    </div>
                </div>
            </div>
        </header>

      <main className="single_main">
        <svg
          className="fill_1"
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="166"
          viewBox="0 0 128 166"
          fill="none">
          <path
            d="M-15.3979 0.690292L127.673 83.2924L-15.3979 165.894L-15.3979 0.690292Z"
            fill="#A3DF4A"
            fill-opacity="0.3"
          />
        </svg>
        <svg
          className="fill_3"
          xmlns="http://www.w3.org/2000/svg"
          width="128"
          height="166"
          viewBox="0 0 128 166"
          fill="none">
          <path
            d="M-15.3979 0.690292L127.673 83.2924L-15.3979 165.894L-15.3979 0.690292Z"
            fill="#A3DF4A"
            fill-opacity="0.3"
          />
        </svg>
        <img src={fill_w} alt="" className="fill_2" />
        <div className="container">
          <div className="product_ditails">
            <h1>{productDetails.name}</h1>
            <div className="product-info">
              <img src={`https://rezina13.com.ua/image/${productDetails.image}`} alt="" />
              {isTyre && (
                <div className="product-list-info">
                  <div className="top-list">
                    <div className="item">
                      <span className="title">ДОСТУПНІСТЬ</span>
                      <span className="description">В наявності</span>
                    </div>
                    <div className="item">
                      <span className="title">АРТИКУЛ ТОВАРА:</span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'idTyreTrader')[0]
                      ? productAttributes.filter((attr) => attr.name == 'idTyreTrader')[0].value : 'Не найдено'}</span>
                    </div>
                  </div>
                  <div className="hero-list">
                    <div className="item">
                      <span className="title">
                        <img src={s1} alt="" />
                        БРЕНД:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Бренд')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Бренд')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s3} alt="" />
                        ШИРИНА:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Ширина')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Ширина')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s2} alt="" />
                        СТРАНА ПРОИЗВОДИТЕЛЬ:
                      </span>
                      <span className="description">{(productAttributes.filter((attr) => attr.name == 'Страна')[0]
                                          || productAttributes.filter((attr) => attr.name == 'Страна Производитель')[0] || {value: 'Не найдено'}).value}</span>
                    </div>

                    <div className="item">
                      <span className="title">
                        <img src={s4} alt="" />
                        ВЫСОТА:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Высота')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Высота')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s5} alt="" />
                        ДИАМЕТР:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Диаметр')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Диаметр')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s6} alt="" />
                        ИНДЕКС НАГРУЗКИ:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Индекс нагрузки')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Индекс нагрузки')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s7} alt="" />
                        ИНДЕКС СКОРОСТИ:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Индекс скорости')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Индекс скорости')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s8} alt="" />
                        СЕЗОН:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Сезон')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Сезон')[0].value : 'Не найдено'}</span>
                    </div>
                    {/* <div className="item">
                                            <span className="title"><img src={s9} alt="" />ТИП ТС:</span>
                                            <span className="description">{textValue}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title"><img src={s10} alt="" />МОДЕЛЬ:</span>
                                            <span className="description">{textValue}</span>
                                        </div> */}
                  </div>
                </div>
              )}
              {isDiski && (
                <div className="product-list-info">
                  <div className="top-list">
                    <div className="item">
                      <span className="title">ДОСТУПНІСТЬ</span>
                      <span className="description">В наявності</span>
                    </div>
                    <div className="item">
                      <span className="title">АРТИКУЛ ТОВАРА:</span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'idTyreTrader')[0]
                      ? productAttributes.filter((attr) => attr.name == 'idTyreTrader')[0].value : 'Не найдено'}</span>
                    </div>
                  </div>
                  <div className="hero-list">
                    <div className="item">
                      <span className="title">
                        <img src={s1} alt="" />
                        БРЕНД:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Бренд')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Бренд')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s2} alt="" />
                        PCD:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'pcd1')[0]
                      ? productAttributes.filter((attr) => attr.name == 'pcd1')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s3} alt="" />
                        ШИРИНА:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Ширина Диска')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Ширина Диска')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s4} alt="" />
                        DIA:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'dia')[0]
                      ? productAttributes.filter((attr) => attr.name == 'dia')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s5} alt="" />
                        ДИАМЕТР:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Диаметр')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Диаметр')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s6} alt="" />
                        ET:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'et')[0]
                      ? productAttributes.filter((attr) => attr.name == 'et')[0].value : 'Не найдено'}</span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s7} alt="" />
                        Тип:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'wheel_type')[0]
                      ? productAttributes.filter((attr) => attr.name == 'wheel_type')[0].value : 'Не найдено'}
                      </span>
                    </div>
                    <div className="item">
                      <span className="title">
                        <img src={s8} alt="" />
                        Крепеж:
                      </span>
                      <span className="description">{productAttributes.filter((attr) => attr.name == 'Число крепежных отверстий')[0]
                      ? productAttributes.filter((attr) => attr.name == 'Число крепежных отверстий')[0].value : 'Не найдено'}</span>
                    </div>
                  </div>
                </div>
              )}
              {isAcss && (
                <div className="product-list-info">
                  <div className="top-list">
                    <div className="item">
                      <span className="title">ДОСТУПНІСТЬ</span>
                      <span className="description">В наявності</span>
                    </div>
                  </div>
                  <div className="hero-list">
                  {productAttributes && productAttributes.length > 0 ? (
  productAttributes.map((obj, index) => (
    <div className="item" key={index}>
      <span className="title">
        <img src={s1} alt="" />
        {obj.name}:
      </span>
      <span className="description">{obj.value}</span>
    </div>
  ))
) : (
  <div>Атрибуты отсутствуют</div>
)}
                  </div>
                </div>
              )}
            </div>
            <div className="order_product_list">
              <div className="callback">
                <span className="title">Потрібна Допомога?</span>
                <p>
                  Допоможемо підібрати товар і відповімо <br />
                  на будь-які питання
                </p>
                <a href="tel:+38 095 706 45 00">095 706 45 00</a>
                <a href="tel:+38 095 706 45 00" className="call_button">
                  <img src={call} alt="" />
                </a>
              </div>
              <div className="order_nav">
                <div className="top">
                  <div className="quantity">
                    <span className="title">КІЛЬКІСТЬ</span>
                    <div className="quantity-controls">
                      <button onClick={decrementQuantity}>-</button>
                      <span>{quantity}</span>
                      <button onClick={incrementQuantity}>+</button>
                    </div>
                  </div>

                  <div className="logotype">
                    <img src={visa} alt="" />
                    <img src={nova} alt="" />
                  </div>
                </div>

                <div className="bottom">
                  <div className="price">{productDetails.price}</div>
                  <a className="add_to_cart" onClick={handleOrderClick}>
                    {" "}
                    <img src={cart} alt="" /> Замовити
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="related_product">
            <h2>Схожі товари</h2>
            <div className="related_post_area">
              {isTyre && tiresData &&
                  tiresData
                  .slice(0, visibleProducts)
                  .map((obj, index) => (
                    <SingleRelatedItem key={index} {...obj} />
                  ))}

              {isDiski && diskiData &&
                  diskiData
                  .slice(0, visibleProducts)
                  .map((obj, index) => (
                    <SingleRelatedItem key={index} {...obj} />
                  ))}

              {isAcss && accData &&
                  accData
                  .slice(0, visibleProducts)
                  .map((obj, index) => (
                    <SingleRelatedItem key={index} {...obj} />
                  ))}
            </div>
          </div>*/}
        </div>
      </main>
      <Footer />
    </>
  );
}

export default SingleProduct;
