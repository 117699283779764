
import './App.css';
import './scss/Main.scss';
import Header from './dom/header';
import Footer from './dom/Footer';
import Main from './dom/Main';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SingleProduct from './component/SingleProduct';
import DiskiList from './component/DiskiList';
import Accesuars from './dom/Accesuars';

function Layout() {
  const location = useLocation();
  const isProductPage = location.pathname.startsWith('/product/');
  const isDiskiPage = location.pathname === '/diski';
  const isAccesuars = location.pathname === '/accessories';

  return (
    <>
      {!isProductPage && !isAccesuars && !isDiskiPage && <Header />}
      {!isProductPage && !isAccesuars  && !isDiskiPage && <Main />}
      {!isProductPage && !isAccesuars  && !isDiskiPage && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
      <Routes>
        <Route path="/product/:id" element={<SingleProduct />} />
        <Route path="/diski" element={<DiskiList />} />
        <Route path="/accessories" element={<Accesuars />} />
      </Routes>
    </Router>
  );
}
export default App;
