import fl_1 from "../image/fl_1.png";
import fl_2 from "../image/fl_2.png";
import fl_3 from "../image/fl_3.png";
import fl_4 from "../image/fl_4.png";
import fl_5 from "../image/fl_5.png";
import arrow from "../image/arrow.png";
import search from "../image/search.png";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFilter } from "../redux/actions";

function Filter({ updateFilter, activeFilter }) {
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedWidth, setSelectedWidth] = useState("");
  const [seasons, setSeasons] = useState([]);
  const [widths, setWidths] = useState([]);
  const [clickCount, setClickCount] = useState(0);
  const [currentContent, setCurrentContent] = useState("seasons");
  const [heights, setHeights] = useState([]);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [diameters, setDiameters] = useState([]);
  const [selectedDiameter, setSelectedDiameter] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const dispatch = useDispatch();

  const toggleActive = () => {
    setIsActive(false);
    setTimeout(() => setIsActive(true), 0);
  };
  // достаем марку авто

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/data")
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);
  // производитель
  useEffect(() => {
    if (data && Array.isArray(data)) {
      const uniqueVendors = Array.from(
        new Set(data.map((item) => item.vendor))
      );
      setVendors(uniqueVendors);
    }
  }, [data]); // Добавьте data в массив зависимостей, чтобы useEffect срабатывал при изменении data

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setCurrentContent("vendors"); // Обновляем контент на производителей
    setClickCount((prevCount) => prevCount + 1);
    toggleActive();
  };
  // модель

  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");

  useEffect(() => {
    if (data && Array.isArray(data) && (selectedVendor && selectedVendor.length > 0)) {
      const carsFromVendor = Array.from(
        new Set(
          data
            .filter((item) => item.vendor === selectedVendor)
            .map((item) => item.car) // Предполагается, что у каждого элемента в data есть поле car
        )
      );
      setCars(carsFromVendor);
    }
  }, [data, selectedVendor]); // Добавьте selectedVendor в массив зависимостей

  // Теперь вам нужно определить обработчик клика для выбора машины, если это необходимо
  const handleCarClick = (car) => {
    // Ваш код для обработки клика по машине
    // Например, вы можете установить выбранную машину в состояние
    setSelectedCar(car);
    // И обновить текущий контент, если это необходимо
    setCurrentContent("cars");
    // И другие действия, которые нужно выполнить при клике на машину
  };
  // год

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    if (data && Array.isArray(data) && selectedVendor && selectedVendor.length >0 && selectedCar && selectedCar.length > 0) {
      const yearsFromCar = Array.from(
        new Set(
          data
            .filter(
              (item) =>
                item.vendor === selectedVendor && item.car === selectedCar
            )
            .map((item) => item.year) // Предполагается, что у каждого элемента в data есть поле year
        )
      );
      setYears(yearsFromCar);
    }
  }, [data, selectedVendor, selectedCar]); // Добавьте selectedVendor и selectedCar в массив зависимостей

  // Обработчик клика для выбора года выпуска
  const handleYearClick = (year) => {
    // Ваш код для обработки клика по году выпуска
    // Например, вы можете установить выбранный год в состояние
    setSelectedYear(year);
    // И обновить текущий контент, если это необходимо
    setCurrentContent("years");
    // И другие действия, которые нужно выполнить при клике на год выпуска
  };
  // console.log(data)

  // модификация

  const [modifications, setModifications] = useState([]);
  const [selectedModification, setSelectedModification] = useState("");

  useEffect(() => {
    if (
      data &&
      Array.isArray(data) &&
      selectedVendor && selectedVendor.length > 0 &&
      selectedCar && selectedCar.length > 0 &&
      selectedYear && selectedYear.length > 0
    ) {
      const modificationsFromCar = Array.from(
        new Set(
          data
            .filter(
              (item) =>
                item.vendor === selectedVendor &&
                item.car === selectedCar &&
                item.year === selectedYear
            )
            .map((item) => item.modification) // Предполагается, что у каждого элемента в data есть поле modification
        )
      );
      setModifications(modificationsFromCar);
    }
  }, [data, selectedVendor, selectedCar, selectedYear]); // Добавьте selectedVendor, selectedCar и selectedYear в массив зависимостей

  // Обработчик клика для выбора модификации
  const handleModificationClick = (modification) => {
    setSelectedModification(modification);
    setCurrentContent("modifications");
    // И другие действия, которые нужно выполнить при клике на модификацию
  };
  const [resultShini, setResult] = useState(null);
  // результат
  useEffect(() => {
    if (
      data &&
      Array.isArray(data) &&
      selectedVendor && selectedVendor.length > 0 &&
      selectedCar && selectedCar.length > 0 &&
      selectedYear && selectedYear.length > 0 &&
      selectedModification && selectedModification.length > 0
    ) {
      const zamenShiniResults = data
        .filter(
          (item) =>
            item.vendor === selectedVendor &&
            item.car === selectedCar &&
            item.year === selectedYear &&
            item.modification === selectedModification
        )
        .map((item) => item.zamen_shini); // Предполагается, что у каждого элемента в data есть поле zamen_shini

      // Выводим результат в консоль
      setResult(zamenShiniResults);
    }
  }, [data, selectedVendor, selectedCar, selectedYear, selectedModification]); // Добавьте selectedModification в массив зависимостей

  const [widthNum, setWidthNum] = useState(null);
  const [heightNum, setHeightNum] = useState(null);
  const [diameterNum, setDiameterNum] = useState(null);

  useEffect(() => {
    if (resultShini && resultShini.length > 0) {
      const [width, height, diameter] = resultShini[0]
        .match(/(\d+)\/(\d+) R(\d+)/)
        .slice(1);

      setWidthNum(parseInt(width, 10));
      setHeightNum(parseInt(height, 10));
      setDiameterNum(parseInt(diameter, 10));
    }
  }, [resultShini]);
 
  // сезон
  useEffect(() => {
    const uniqueSeasons = Array.from(
      new Set([
        "Летние",
        "Зимние",
        "Всесезонные"
      ])
    );
    setSeasons(uniqueSeasons);
  }, []);
  const handleSeasonClick = (season) => {
    if (season && season.length > 0) {
      setSelectedSeason(season);
    }
    setCurrentContent("seasons"); // Обновляем контент на сезоны
    setClickCount((prevCount) => prevCount + 1);
    toggleActive();
  };

  // ширина
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/tires-width-new")
      .then((response) => response.json())
      .then((fetchedData) => setWidths(fetchedData.map(Object.values).map((item) => item[0])));
  }, []);
  const handleWidthClick = (width) => {
    if (width && width.length > 0) {
      setSelectedWidth(width);
    }
    setCurrentContent("widths"); // Обновляем контент на ширину
    toggleActive();
  };

  // высота
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/tires-height-new")
      .then((response) => response.json())
      .then((fetchedData) => setHeights(fetchedData.map(Object.values).map((item) => item[0])));
  }, []);
  const handleHeightClick = (height) => {
    if (height && height.length > 0) {
      setSelectedHeight(height);
    }
    setCurrentContent("heights"); // Обновляем контент на высоту
    toggleActive();
  };

  // диаметр
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/tires-diametr-new")
      .then((response) => response.json())
      .then((fetchedData) => setDiameters(fetchedData.map(Object.values).map((item) => item[0])));
  }, []);
  const handleDiameterClick = (diameter) => {
    if (diameter && diameter.length > 0) {
      setSelectedDiameter(diameter);
    }
    setCurrentContent("diameters"); // Обновляем контент на диаметр
    toggleActive();
  };

  // бренд
  useEffect(() => {
    fetch("https://lp.rezina13.com.ua/tires-brand-new")
      .then((response) => response.json())
      .then((fetchedData) => setBrands(fetchedData.map(Object.values).map((item) => item[0])));
  }, []);
  const handleBrandClick = (brand) => {
    if (brand && brand.length > 0) {      
      setSelectedBrand(brand);
    }
    setCurrentContent("brands"); // Обновляем контент на бренд
    toggleActive();
  };

  const handleSearchClick = (event) => {
    // Получаем атрибуты из элемента, по которому был совершен клик
    const filters = {
      season: event.currentTarget.getAttribute("data-season"),
      width: event.currentTarget.getAttribute("data-width"),
      height: event.currentTarget.getAttribute("data-height"),
      diameter: event.currentTarget.getAttribute("data-diameter"),
      brand: event.currentTarget.getAttribute("data-brand"),
    };

    // Отправляем действие с данными фильтра в хранилище
    dispatch(setFilter(filters));

    // Если нужно, вызываем updateFilter для обновления состояния вне Redux
    updateFilter("season", filters.season);
    updateFilter("width", filters.width);
    updateFilter("height", filters.height);
    updateFilter("diameter", filters.diameter);
    updateFilter("brand", filters.brand);

    document.getElementById("main").scrollIntoView({behavior: 'smooth'})
  };

  const [currentFilter, setCurrentFilter] = useState("param"); // 'param' или 'mark'
  return (
    <div className="fillter_wrap">
      <div className="fillter">
        <div className="item" data-fillter="param">
          <div className="item-title ses" onClick={handleSeasonClick}>
            <span>Сезонність {selectedSeason.length > 0 ? selectedSeason : ""}</span>
            <img src={fl_1} onClick={handleSeasonClick} alt="" />
          </div>
          <img src={arrow} onClick={handleSeasonClick} alt="" />
        </div>
        <div className="item" data-fillter="param">
          <div className="item-title" onClick={handleWidthClick}>
            <span>Ширина {selectedWidth.length > 0 ? selectedWidth : ""}</span>
            <img src={fl_2} onClick={handleWidthClick} alt="" />
          </div>
          <img src={arrow} onClick={handleWidthClick} alt="" />
        </div>
        <div className="item" data-fillter="param">
          <div className="item-title" onClick={handleHeightClick}>
            <span>Профіль {selectedHeight.length > 0 ? selectedHeight : ""}</span>
            <img src={fl_3} onClick={handleHeightClick} alt="" />
          </div>
          <img src={arrow} onClick={handleHeightClick} alt="" />
        </div>
        <div className="item" data-fillter="param">
          <div className="item-title" onClick={handleDiameterClick}>
            <span>Діаметр {selectedDiameter.length > 0 ? selectedDiameter : ""}</span>
            <img src={fl_4} onClick={handleDiameterClick} alt="" />
          </div>
          <img src={arrow} onClick={handleDiameterClick} alt="" />
        </div>
        <div className="item brand" data-fillter="param">
          <div className="item-title" onClick={handleBrandClick}>
            <span>Бренд {selectedBrand.length > 0 ? selectedBrand : ""}</span>
            <img src={fl_5} onClick={handleBrandClick} alt="" />
          </div>
          <img src={arrow} onClick={handleBrandClick} alt="" />
        </div>
        {/* по марке авто  */}
        <div className="item" data-fillter="mark">
          <div className="item-title" onClick={handleVendorClick}>
            <span>Марка авто {selectedVendor.length > 0 ? selectedVendor : ""}</span>
            <img src={fl_5} onClick={handleVendorClick} alt="" />
          </div>
          <img src={arrow} onClick={handleVendorClick} alt="" />
        </div>
        <div className="item" data-fillter="mark">
          <div className="item-title" onClick={handleCarClick}>
            <span>Модель {selectedCar.length > 0 ? selectedCar : ""}</span>
            <img src={fl_5} onClick={handleCarClick} alt="" />
          </div>
          <img src={arrow} onClick={handleCarClick} alt="" />
        </div>
        <div className="item" data-fillter="mark">
          <div className="item-title" onClick={handleYearClick}>
            <span>Рік випуску {selectedYear.length > 0 ? selectedYear : ""}</span>
            <img src={fl_5} onClick={handleYearClick} alt="" />
          </div>
          <img src={arrow} onClick={handleYearClick} alt="" />
        </div>
        <div className="item" data-fillter="mark">
          <div className="item-title" onClick={handleModificationClick}>
            <span>Модифікація {selectedModification.length > 0 ? selectedModification : ""}</span>
            <img src={fl_5} onClick={handleModificationClick} alt="" />
          </div>
          <img src={arrow} onClick={handleModificationClick} alt="" />
        </div>
        <input
          type="hidden"
          data-marks={selectedVendor}
          data-model={selectedCar}
          data-year={selectedYear}
          data-modif={selectedModification}
        />
        {/* конец по марке авто */}
        <button
          className="search"
          onClick={handleSearchClick}
          data-season={activeFilter === "param" ? selectedSeason : undefined}
          data-width={activeFilter === "param" ? selectedWidth : widthNum}
          data-height={activeFilter === "param" ? selectedHeight : heightNum}
          data-diameter={
            activeFilter === "param" ? selectedDiameter : diameterNum
          }
          data-brand={activeFilter === "param" ? selectedBrand : undefined}>
          <img src={search} alt="" />
          Знайти
        </button>
      </div>

      <div className={`fillter-result ${isActive ? "active" : ""}`}>
        {currentContent === "seasons" &&
          seasons.map((season) => (
            <li
              key={season}
              className={selectedSeason === season ? "active" : ""}
              data-filter-result={season}
              onClick={() => {
                handleSeasonClick(season);
                setClickCount((prevCount) => prevCount + 1);
              }}>
              {season}
            </li>
          ))}
        {currentContent === "widths" &&
          widths
            .sort((a, b) => a - b)
            .map((width) => (
              <li
                key={width}
                className={selectedWidth === width ? "active" : ""}
                data-filter-result={width}
                onClick={() => {
                  handleWidthClick(width);
                  setClickCount((prevCount) => prevCount + 1);
                }}>
                {width}
              </li>
            ))}
        {currentContent === "heights" &&
          heights
            .sort((a, b) => a - b)
            .map((height) => (
              <li
                className={selectedHeight === height ? "active" : ""}
                key={height}
                data-filter-result={height}
                onClick={() => {
                  handleHeightClick(height);
                  setClickCount((prevCount) => prevCount + 1);
                }}>
                {height}
              </li>
            ))}
        {currentContent === "diameters" &&
          diameters
            .sort((a, b) => a - b)
            .map((diameter) => (
              <li
                className={selectedDiameter === diameter ? "active" : ""}
                key={diameter}
                data-filter-result={diameter}
                onClick={() => {
                  handleDiameterClick(diameter);
                  setClickCount((prevCount) => prevCount + 1);
                }}>
                {diameter}
              </li>
            ))}
        {currentContent === "brands" &&
          brands.map((brand) => (
            <li
              className={selectedBrand === brand ? "active" : ""}
              key={brand}
              data-filter-result={brand}
              onClick={() => {
                handleBrandClick(brand);
                setClickCount((prevCount) => prevCount + 1);
              }}>
              {brand}
            </li>
          ))}

        {currentContent === "vendors" &&
          vendors.map((vendor) => (
            <li
              className={selectedVendor === vendor ? "active" : ""}
              key={vendor}
              data-filter-result={vendor}
              onClick={() => {
                handleVendorClick(vendor);
                setClickCount((prevCount) => prevCount + 1);
              }}>
              {vendor}
            </li>
          ))}

        {currentContent === "cars" &&
          cars.map((car, index) => (
            <li
              className={selectedCar === car ? "active" : ""}
              key={index}
              data-filter-result={car}
              onClick={() => {
                handleCarClick(car);
                setClickCount((prevCount) => prevCount + 1);
              }}>
              {car}
            </li>
          ))}

        {currentContent === "years" &&
          years
            .sort((a, b) => b - a)
            .map((year) => (
              <li
                className={selectedYear === year ? "active" : ""}
                key={year}
                data-filter-result={year}
                onClick={() => {
                  handleYearClick(year);
                  setClickCount((prevCount) => prevCount + 1);
                }}>
                {year}
              </li>
            ))}

        {currentContent === "modifications" &&
          modifications.sort().map((modification) => (
            <li
              className={selectedModification === modification ? "active" : ""}
              key={modification}
              data-filter-result={modification}
              onClick={() => {
                handleModificationClick(modification);
                setClickCount((prevCount) => prevCount + 1);
              }}>
              {modification}
            </li>
          ))}
      </div>
    </div>
  );
}

export default Filter;
