import Social from "../component/Social";
import Menu from '../dom/Menu';
import { Link } from 'react-router-dom';
import logo from '../image/logo.png';
import phone from '../image/phone-white.png';
import ProductItem from "../component/ProductItem";
import React, { useState, useEffect } from 'react';
import Footer from "../dom/Footer";

function Accesuars() {
    const [visibleProducts, setVisibleProducts] = useState(12); // начальное состояние
    const loadMoreProducts = () => {
        setVisibleProducts(prevVisibleProducts => prevVisibleProducts + 12); // увеличиваем количество отображаемых товаров на 12
    };

    const [data, setData] = useState([]);
    useEffect(() => {
      fetch("https://lp.rezina13.com.ua/acc-new")
        .then((response) => response.json())
        .then((fetchedData) => setData(fetchedData));
    }, []);

    return(
        <>
         <header className="accessories">
            <div className="container">
                <div className="top_line accessories">
                    <div className="logo">
                        <a href="/" className="logo"><img src={logo} alt="" /></a>
                    </div>
                    <Menu />
                    <Social />
                    <div className="phone_area">
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:095 706 45 00">095 706 45 00</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:098 858 23 80">098 858 23 80</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:093 707 88 01">093 707 88 01</a></div>

                    </div>
                </div>
            </div>
        </header>

            <section className="main">
            <h2>Результат за Вашими параметрами</h2>
            <div className="container">
        

            <div className="catalog">
            {data
                .slice(0, visibleProducts)
                .map((obj, index) => <ProductItem key={index} {...obj}  />)
            }
               
            </div>
            <button className="load"  onClick={loadMoreProducts}>Завантажити Ще</button>
            </div>
        </section>
        <Footer />
        </>
    )
}

export default Accesuars;