// store.js
import { configureStore } from '@reduxjs/toolkit';
import {filtersReducer, productReducer} from './reducers';

const store = configureStore({
  reducer: {
    filters: filtersReducer,
    productDetails: productReducer
  },
});

export default store;