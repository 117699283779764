import info from '../image/info.png';
import blackcart from '../image/blackcart.png';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProductDetails } from '../redux/actions';
function SingleRelatedItem(props) {
    console.log(props)

    const dispatch = useDispatch();

    const handleClick = () => {
      dispatch(setProductDetails(props));
      // Здесь может быть код для перехода на страницу продукта
    };
    
    const fadeOutAndScrollToTop = () => {
        handleClick();
        document.body.style.transition = 'opacity 0.5s';
        document.body.style.opacity = '0';

        setTimeout(() => {
            window.scrollTo(0, 0);
            document.body.style.opacity = '1';
        }, 500); // время в миллисекундах должно соответствовать длительности transition
    };

    return (
        <>
    
            <div className="item">
                <img src={props.thumb} alt="" />
                <div className="hero">
                    <span className="title">{props.name}</span>
                    <div className="ditail">
                    
                        <div className="item">
                            <span className=" title">ДОСТУПНІСТЬ</span>
                            <span className="description">В наявності </span>
                        </div>
                        <div className="item">
                            <span className=" title">АРТИКУЛ ТОВАРА:</span>
                            <span className="description">{props.attributes?.[0]?.attribute?.find(attr => attr.name === 'idTyreTrader')?.text}</span>
                        </div>
               
                    </div>
                </div>
                <div className="price">{props.price}</div>
                <div className="ditail_navigation">
                <Link to={`/product/${props.product_id}`} className="more" onClick={fadeOutAndScrollToTop}><img src={info} alt="" /></Link>
                    <a className="addt_to_cart_ditail"><img src={blackcart} alt="" /></a>
                </div>
            </div>
            
        </>
    )
}

export default SingleRelatedItem;