import logo from "../image/logo.png";
import phone from "../image/phone-white.png"
import clock from "../image/clock.png";
import point from "../image/point.png"
import speed from "../image/speed.png"
import OrderModal from "../component/OrderModal";


function Footer() {
    return (
        <>
        <footer>
            <div className="container">
                <img src={logo} alt="" />
                <div className="phone_area">
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:095 706 45 00">095 706 45 00</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:098 858 23 80">098 858 23 80</a></div>
                        <div className="phone-item"> <img src={phone} alt="" /><a href="tel:093 707 88 01">093 707 88 01</a></div>

                </div>
                <div className="clock">
                    <img src={clock} alt="" />
                    <p>ПН-ПТ: С 09:00 ДО 18:00 <br /> СБ: С 09:00 ДО 16:00</p>
                </div>
                <div className="point">
                    <img src={point} alt="" />
                    <p>ХАРЬКОВ, ПЕР. ЛАЗКОВСКИЙ, 8</p>
                </div>
            </div>
            <small>All rights reserved.</small>
        </footer>
        <OrderModal/>
        </>
    )
}

export default Footer;