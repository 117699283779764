// actions.js
export const setFilter = (filters) => ({
  type: 'SET_FILTERS',
  payload: filters,
});

export const updateFilters = (filters) => {
  return {
      type: 'UPDATE_FILTERS',
      payload: filters,
  };
};

export const setProductDetails = (productDetails) => {
  // console.log(productDetails)
  return {
    type: 'SET_PRODUCT_DETAILS',
    payload: productDetails,
  };
};

export const setOrderProduct = (product) => ({
  type: 'SET_ORDER_PRODUCT',
  payload: product,
});
export const setModalActive = (isActive) => ({
  type: 'SET_MODAL_ACTIVE',
  payload: isActive,
});