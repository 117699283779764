import imageDefault from '../image/no-image.png';
import seasson from '../image/seasson_1.png';
import tyre from '../image/type_car.png'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProductDetails,  setOrderProduct, setModalActive  } from '../redux/actions';

function ProductItem(obj) {
    const handleError = (e) => {
        e.target.onerror = null; // чтобы избежать бесконечного цикла, если запасное изображение также не загружается
        e.target.src = imageDefault; // замените на путь к вашему запасному изображению
    };
    const dispatch = useDispatch();

    const handleClick = () => {
      dispatch(setProductDetails(obj));
      // Здесь может быть код для перехода на страницу продукта
      document.body.style.transition = 'opacity 0.5s';
        document.body.style.opacity = '0';

        setTimeout(() => {
            window.scrollTo(0, 0);
            document.body.style.opacity = '1';
        }, 500); // время в миллисекундах должно соответствовать длительности transition
    };
  
    const handleOrderClick = () => {
        dispatch(setOrderProduct(obj));
        dispatch(setModalActive(true));
    
      };
    return(
        <>
       
            <div className="item">
                <div className="image">
                    <img src={`https://rezina13.com.ua/image/${obj.image}`} onError={handleError}  alt="" />
                    <div className="special">
                        <img src={seasson} alt="" />
                        <img src={tyre} alt="" />
                    </div>
                </div>
                <div className="item_info">
                    <span className="title">{obj.name}</span>
                    <div className="price">{obj.price} грн.</div>
                    <div className="item_nav">
                    <Link to={`/product/${obj.product_id}`}  className="item-link btn more" onClick={handleClick} >Детальніше </Link>
                        <button className='btn order' onClick={handleOrderClick}>Замовити</button>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default ProductItem;